<template>

	<div class="userNews clearfix">
		<div class="userNewsLeft">
			<ul> 
				<li :class="tableindex == 1 ?'active':''" @click="clickActive(1)">
					关于我们
				</li>
				<!-- <li :class="tableindex == 2 ?'active':''" @click="clickActive(2)">
					版权声明
				</li> -->
				<li :class="tableindex == 3 ?'active':''" @click="clickActive(3)">
					隐私保护
				</li>
				<li :class="tableindex == 4 ?'active':''" @click="clickActive(4)">
					联系我们
				</li>
				<li :class="tableindex == 5 ?'active':''" @click="clickActive(5)">
					用户服务协议
				</li>
				<li :class="tableindex == 6 ?'active':''" @click="clickActive(6)">
					授权许可协议
				</li>
				
			</ul>
		</div>
		<div class="userNewsRight">
			<div class="agreement">
				<h2>{{info.title}}</h2>
				<div v-html="info.content"></div>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		name: 'userNewsList',
		data() {
			return {
				info: {},
				
				tableindex: 1,
			}
		},
		mounted() {
			this.$parent.tab = 3;
			this.$parent.tabIndex = 1;
			this.$parent.$parent.routerIndex = 0;
			this.$parent.$parent.showIndex = 1;
			
			// if (this.$route.query.id) {
			// 	this.initFun(this.$route.query.id);
			// 	this.tableindex = this.$route.query.id;
				
			// 	console.log("未走",this.tableindex)
				
			// } else {
			// 	this.initFun(1)
			// 	console.log("走",this.tableindex)
			// }

		},
		methods: {
			getPath(){
				this.tableindex = this.$route.query.id;
				let id=1;
				if(this.$route.query.id){
					id=this.$route.query.id;
				}
				this.initFun(id);
				
			
			},
			clickActive(index) {
				this.tableindex = index;
				this.initFun(index)
			},
			initFun(id) {
				var that = this;
				this.$api.POST(this.$face.singleInfo, {
					id: id
				}, function(res) {
					that.info = res.data;
				})
			}

		},
		watch: {
  '$route':'getPath'
},
		activated() {
			this.$nextTick(() => {
				this.$parent.tab = 3;
				this.$parent.tabIndex = 1;
				this.$parent.$parent.routerIndex = 0;
				this.$parent.$parent.showIndex = 1;
			});
		}
	}
</script>

<style scoped>
	.userNews {
		min-height: 400px;
		min-width: 1200px;
		max-width: 1400px;
		width: 72%;
		margin: 0 auto 39px;
		padding-top: 13px;
	}

	.userNewsLeft {
		/* min-width: 215px; */
		background-color: #fff;
		border-radius: 10px;
		width: 15%;
		float: left;
	}

	.userNewsLeft ul {
		padding: 37px 0 0;
		overflow: hidden
	}

	.userNewsLeft li {
		font-size: 16px;
		color: #333;
		padding-left: 37px;
		margin-bottom: 37px;
		cursor: pointer;
		height: 20px;
		line-height: 20px;
		position: relative;
	}

	.userNewsLeft li::before {
		display: block;
		width: 6px;
		height: 20px;
		content: ' ';
		background-color: #fff;
		position: absolute;
		left: 0;
		top: 0;
		border-radius: 3px;
	}

	.userNewsLeft li.active::before {
		background-color: #5957ff;
	}

	.userNewsLeft li.active {
		color: #5957ff;
	}

	.userNewsRight {
		width: 75%;
		/* min-width: 1175px; */
		background: #fff;
		border-radius: 10px;
		float: right;
		padding: 15px 4% 0;
		min-height: 500px;

	}

	.agreement {
		background: #FFF;
		padding-bottom: 20px;
		box-sizing: border-box;
	}

	.agreement h2 {
		text-align: center;
		font-size: 24px;
		line-height: 60px;
	}

	.agreement>div {
		padding: 0 50px;
	}

	.agreement>>>p {
		font-size: 14px;
		line-height: 22px;
	}
</style>
